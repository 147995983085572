html {
    font-size: inherit!important;
}

#root {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    color: #3D516F;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #3D516F;
}
